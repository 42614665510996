const he = {
  button: {
    resetPassword: "Reset password",
    submit: "Submit",
    continueWithEmail: "Continue with email",
    continueWithGoogle: "Continue with Google",
    continueWithApple: "Continue with Apple",
    signUpWithGoogle: 'Sign up with Google',
    startGifting: "Start gifting",
    selectEdit: "Select & Edit",
    createNewAccount: "Create new account",
    resendConfirmation: "Resend verification link",
    sendResetLink: "Send reset link",
    resend: "Resend",
    login: "Log in",
    continue: "Continue",
    continueWithPassword: "Continue with password",
    next: "Next",
    enter: "Enter",
  },
  text: {
    emailConfirmation: "Email confirmation",
    weJustSentVerification: "We just sent you a verification email.",
    checkInboxConfirm: 'Please <b>check your inbox</b> and click on the verification link to activate your account',
    checkInboxResetPassword: "Check your inbox <br/>We've just sent a link to reset your password.",
    didntReceive: "Didn't receive it?",

    privacyPolicy:
        "By clicking “Continue with Google/Email” above, you acknowledge that you have read and understood, and agree to <a href='https://gifted.co/terms' target='_blank'>Terms & Conditions</a> and <a href='https://gifted.co/privacy' target='_blank'>Privacy Policy</a>",
    forgotPassword: "Forgot password ?",
    login: "Login",
    signUp: "Sign up",
    saveMeFor30Days: "Don’t ask me again for 30 days",
    createNewPlatform: "Create New Platform",
    createNewSupplier: "Create New Supplier",
    checkInbox: "Check your inbox for the link to reset your password",
    back: "Back",
    resetPassword: "Reset password",
    logout: "Logout",
    typeSystemTitle: "Employee Recognition Platform",
    typeSupplierTitle: "Supplier Platform",
    accounts: "Accounts",
    signOut: "Sign out",
    addAccount: "Add account",
    userAlreadyRegistered: "It looks like a user with these credentials already exists. Log in instead?",
    enterVerificationCode: "Enter the 6 digit verification code sent to {sentTo}",
    confirmEmail: "We just sent you a verification email.<br/> Please <b class='text-bold'>check your inbox</b> and click on the verification link to activate your account.",
    wrongSecretCode: "Wrong secret code.<br> Please try again ({wrongAttepmtsCount} of {wrongAttepmtsLimit} attempts left)",
    signUpSubtitleText: {
      default: "Create your Gifted account or simply ",
      checkPass: "Now, set your password",
      checkUserData: "Tell us a little about yourself",
      companyData: "Tell us a little about your company",
      verificationRequired: "Verification required",
    },
    enterCode: "Enter code",
    sendNewCode: "Send a new code",
    enterCodeSentTo: "Enter the code sent to",
    riskifiedContinue: "I understand, but would like to use my personal email",
    enteredPersonalEmail: "Looks like you entered a personal email address.",
    useWorkEmailAddress: "To ensure all our features are enabled, please use a work email address.",
    signUpLoginTitle: "login",
    addANewGiftingAccount: "Add a new gifting account",
    completeProfileSubtitle: "Great! You are almost there.",
    passwordRules: "Your password must be at least 8 characters long include at least one capital letter, and one number",
    letsCreateYourProfile: "Let's create your profile",
    step: "Step",
    riskifiedEmailFirstSubtitle: "Looks like you signed up with a personal email address {email}",
    riskifiedEmailSecondSubtitle: "To ensure all of our gifting platform features are enabled, please use a work email address - yourname@domainname.com",
    companyInformation: "Company information",
    backToLogIn: "Back to log in",
    termsLabels: "<label for='{label}'>I agree to the </label> <a href='https://gifted.co/terms' target='_blank'>Terms & Conditions</a> <label for='{label}'> and </label> <a href='https://gifted.co/privacy' target='_blank'>Privacy Policy</a>",
    signUoCompanyCreationPurposeTitle: "Who will you be sending these gifts to?",
    passwordValidation: {
      characters: "At least 8 characters",
      capitalLetter: "At least one capital letter",
      number: "At least one number",
      match: "Must match Password",
    },
    creationPurposes: {
      myTeam: "My team",
      customers: "Customers",
      salesProspects: "Sales prospects"
    },
    acceptTerms: "Please agree to the Terms & Conditions and Privacy Policy to proceed",
    choosePurpose: "Please select a primary use for Gifted from the options provided",
    pleaseEnterCode: "Please enter the code to proceed",
    enteredCodeIsIncorrect: "Oops! The entered code is incorrect. Please double-check and try again",
  },
  title: {
    signIn: "Gifting Platform | Sign In",
    signUp: "Gifting Platform | Sign Up",
    dashboard: "Gifting Platform | Dashboard",
    createNewPlatform: "Gifting Platform | Create New Platform",
    createNewSupplier: "Gifting Platform | Create New Supplier"
  },
  placeholder: {
    enterEmailAddress: "Enter your email address",
    enterPassword: "Enter password",
    enterSecretCode: "Enter secret code",
    confirmPassword: "Confirm password",
    enterCode: "Enter code",
    code: "Code",
    enterYourWorkEmail: "Enter your work email",
    chooseCountry: "Choose country",
    firstName: "First name",
    lastName: "Last name",
    phone: "Phone",
    phoneNumber: "Phone number",
    companyName: "Company name",
    companyEmployees: "Number of employees",
    workEmail: "Work email",
    password: 'Password',
  },
  header: {
    startNewPlatform: "Start a new platform",
    giftingPlatforms: "My gifting platforms",
    supplierPlatform: "Supplier Platform",
    employeeRecognition: "Employee Recognition Platform"
  },
  info: {
    supplierPlatform: "Manage your orders and reports with ease",
    employeeRecognition: "Send the perfect gift for any occasion",
    emailNotConfirmed: "Please confirm your email to use platform!",
    emailConfirmed: "Your email was confirmed."
  },
  app: {
    mainTitle: "Gifted | My Gifting Platforms"
  }
};

export default he;
