import { SignUpParams, SignUpParamsCompletion, SignUpResponse } from "@/types/sign-up";
import type { HttpResponse } from "@/types/http";

import { httpPost } from "@/services/http.service";

const SIGN_UP_URL = "hr-system/checkEmailAndRegister";
const SIGN_UP_COMPLETION_URL = "";
const SIGN_UP_GOOGLE = "";

export const signUp = async (params: SignUpParams): Promise<SignUpResponse> => {
    return httpPost(SIGN_UP_URL, { ...params });
};
