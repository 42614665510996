import { RouteNames } from "@/consts/router";

export default [
  {
    path: "/invitation/:type/:tkn",
    name: RouteNames.INVITATION,
    component: () => import("../views/InvitationView.vue"),
    props: true
  }
];
