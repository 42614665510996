
import {defineComponent, ref, reactive, onMounted, computed, watchEffect, getCurrentInstance} from "vue";
import {HTTP} from "@/main";
// components
import Header from "@/components/Header.vue";
import UiPreloaderContainer from "@/components/ui-components/preloader/UiPreloaderContainer.vue";
import UiPreloader from "@/components/ui-components/preloader/UiPreloader.vue";
import UiCodeInput from "@/components/ui/UiCodeInput.vue";
import PasswordRules from "@/components/auth/sign-up/PasswordRules.vue";
import PasswordRulesItem from "@/components/auth/sign-up/PasswordRulesItem.vue";
import UiCheckbox from "@/components/ui-components/UiCheckbox.vue";
// external
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import {Form, Field} from "vee-validate";
import {Providers} from "universal-social-auth";
import axios from "axios";
// services
import {signUp} from "@/services/sign-up.service";
// helpers
import helpers from "@/helpers";
// consts
import {DataGTM} from "@/consts/gtm";
import {ValidationPatterns} from "@/consts/validation-patterns";
import { useFingerprint } from "@/composables/useFingerprint";

export type CreationPurposeItem = {
  id: number,
  name: string
}
export default defineComponent({
  name: "SignUp",
  components: {
    Form,
    Field,
    Header,
    UiPreloaderContainer,
    UiPreloader,
    UiCodeInput,
    PasswordRules,
    PasswordRulesItem,
    UiCheckbox,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const {t} = useI18n();

    const signUpEmail = "signUp-email";
    const steps = {
      checkPass: "checkPass",
      changeRiskifiedEmail: "changeRiskifiedEmail",
      verifyCode: "verifyCode"
    };
    const app = getCurrentInstance();
    const auth = app?.appContext.config.globalProperties.$oauth;
    const riskified = {
      emailTitle: '',
      email: '',
      emailExample: "yourname@domainname.com"
    };

    const userDataForm = ref(null);
    const emailForm = ref(null);
    const confirmPassForm = ref(null);
    const riskifiedForm = ref(null);
    const companyDataForm = ref(null);
    const verifyCodeForm = ref(null);
    const errorMessage = ref("");
    const errorMessageProvider = ref("");
    const showErrorMessageCode = ref("");
    const isPasswordVisible = ref<boolean>(false);
    const creationPurposes = ref<CreationPurposeItem[]>([])
    const creationPurposeValue = ref<CreationPurposeItem | null>(null)
    const acceptTerms = ref<boolean>(false);
    const riskyEmailMessage = ref<boolean>(false);
    const verificationCodeResent = ref<boolean>(false);
    const subscribeNewsUpdates = ref<boolean>(false);
    const subscribeNewsUpdatesError = ref<boolean>(false);

    const settings = reactive({
      stepMode: "checkPass",
      riskyChecked: false,
      previousStep: "",
      isDataProcessing: false,
      signupMethod: '',
      currentProvider: ''
    });
    const user = reactive({
      email: "",
      password: "",
      confirmPassword: "",
      verificationCode: ""
    });
    const fieldsChanged = reactive({
      formDefault: false,
      formCheckPassword: false,
      formVerifyCode: false
    });

    const loginHref = computed(() => {
      return window.location.origin + "/gifting-platform#/";
    });

   const formSubtitle = computed((): string => {
     return settings.stepMode === steps.verifyCode ? t("text.signUpSubtitleText.verificationRequired") : t("text.signUpSubtitleText.default");
   })

    const passHasMinLength = computed((): boolean => {
      return user.password.length >= 8;
    })

    const passContainsCapitalLetters = computed((): boolean => {
      return /[A-Z]/.test(user.password);
    })

    const passContainsNumbers = computed((): boolean => {
      return /\d/.test(user.password);
    })

    const passEqualsConfirmPass = computed((): boolean => {
      return user.password === user.confirmPassword;
    })

    const passValidSymbols = computed((): boolean => {
      const regex = new RegExp(ValidationPatterns.PasswordSymbols);
      return regex.test(user.password);
    })

    const isPasswordValid = computed((): boolean => {
      return passHasMinLength.value && passContainsCapitalLetters.value && passContainsNumbers.value && passEqualsConfirmPass.value && passValidSymbols.value
    })

    const { getRequestId } = useFingerprint();

    const onSubscribeNewsUpdatesChange = (): void => {
      subscribeNewsUpdatesError.value = !subscribeNewsUpdates.value;
    };

    async function sendVerificationCode(payload: { isResend: boolean } = { isResend: false }) {
      if (payload.isResend) verificationCodeResent.value = false;

      try {
        await axios.post('auth/sendVerificationCode', {
          username: user.email
        })

        if (payload.isResend) verificationCodeResent.value = true;
      } catch (error) {
        errorMessage.value = t("text.enteredCodeIsIncorrect", {sentTo: user.email})
        settings.isDataProcessing = false;
      }
    }

    async function verifyUser(code: string) {
      try {
        showErrorMessageCode.value = '';
        settings.isDataProcessing = false;
        if (code.length !== 5) return;

        user.verificationCode = code;
        settings.isDataProcessing = true;
        const {data} = await axios.post("/platform/v2/verifyEmail", {
          username: user.email,
          code: user.verificationCode,
          password: user.password
        })

        const storedAuthData = localStorage.getItem("auth-data");
        const authData = (storedAuthData) ? JSON.parse(storedAuthData) : {};
        localStorage.setItem("auth-data", JSON.stringify({
          ...authData,
          ...data
        }));

        localStorage.removeItem(signUpEmail);
        localStorage.setItem("need-to-reload", "true");
        localStorage.setItem("first-login", "true");
        localStorage.removeItem('registered-via-social');
        localStorage.setItem("hr-system-entrance", "true");
        localStorage.setItem("first-interaction", "true");

        router.push({name: "sign-up-confirm-data"});
      } catch (e: any) {
        settings.isDataProcessing = false;
        showErrorMessageCode.value = e.response.data.error.message;
      }
    }

    const signUpByEmail = async () => {
      const signUpByEmailResponse = await signUp({
        email: user.email,
        password: user.password
      });

      if (signUpByEmailResponse?.data?.message) {
        errorMessage.value = signUpByEmailResponse.data.message;
      } else {
        settings.stepMode = steps.verifyCode;
      }
    }

    async function continueWithChosenEmail() {
      settings.riskyChecked = true;
      continuePassword();
    }

    async function useAuthProvider(provider: any, proData: any) {
      if (!subscribeNewsUpdates.value) {
        subscribeNewsUpdatesError.value = true;
        return;
      }

      try {
        const pro = proData;
        const ProData = pro || Providers[provider];
        errorMessage.value = '';
        errorMessageProvider.value = '';

        // @ts-ignore
        const response = await auth.authenticate(provider, ProData);
        if (response.code) {
          settings.isDataProcessing = true;
          const {data} = await axios.post("platform/v2/social/" + provider, {
            code: response.code,
            checkForRiskyEmail: settings.stepMode !== steps.changeRiskifiedEmail,
          });
          settings.riskyChecked = true;
          if (data.isRisky) {
            settings.stepMode = steps.changeRiskifiedEmail;
            settings.previousStep = steps.checkPass;
            settings.signupMethod = 'social';
            settings.isDataProcessing = false;
            settings.currentProvider = provider;
            return;
          }
          const tokenResponse = await helpers.loadSystems(data.token);

          localStorage.setItem('auth-data', JSON.stringify(data));
          localStorage.setItem('need-to-reload', 'true');
          localStorage.removeItem('signIn-email');
          localStorage.setItem('registered-via-social', 'true');
          localStorage.setItem('signup-with-google', 'true');
          localStorage.setItem("first-login", 'true');
          localStorage.setItem("first-interaction", 'true');

          if (!tokenResponse.data.length) {
            document.location.replace(window.location.origin + '/gifting-platform#/sign-up/create-hr-system');
            return;
          } else {
            // @ts-ignore
            helpers.generateToken(tokenResponse.data[0], data.token).then((redirectUrl) => {
              localStorage.setItem("hr-system-entrance", "true");
              window.location.replace(redirectUrl);
            });
          }
        }
      } catch (e: any) {
        settings.isDataProcessing = false;
        errorMessageProvider.value = e.response.data.message || e.response.data.error;
      }
    }

    function setDefaults() {
      const savedEmail = localStorage.getItem(signUpEmail);
      if (savedEmail) {
        user.email = savedEmail;
        settings.stepMode = steps.checkPass;
      }
    }

    async function continuePassword() {
      if (!subscribeNewsUpdates.value) {
        subscribeNewsUpdatesError.value = true;
        return;
      }

      fieldsChanged.formCheckPassword = false;
      settings.isDataProcessing = true;
      errorMessageProvider.value = '';
      riskyEmailMessage.value = false;
      try {
        //@ts-ignore
        const {valid} = await confirmPassForm.value.validate();

        if (!valid || !isPasswordValid.value) {
          settings.isDataProcessing = false;
          return;
        }

        const {data} = await HTTP.post('/platform/v2/checkEmailAndRegister', {
          email: user.email,
          pass: user.password,
          risky: !settings.riskyChecked
        });
        settings.isDataProcessing = false;

        errorMessage.value = (data?.message) ? data.message : "";

        if (data?.isRisky) {
          riskyEmailMessage.value = true;
        } else {
          settings.stepMode = steps.verifyCode;
        }
      } catch (error) {
        //@ts-ignore
        errorMessage.value = error.response.data.message;
        settings.isDataProcessing = false;
      }
    }

    async function continueRiskifyEmail() {
      //@ts-ignore
      const {valid} = await riskifiedForm.value.validate();
      if (valid) {
        user.email = riskified.email;
        riskified.email = '';
        settings.stepMode = settings.previousStep;
        settings.previousStep = '';
      }
    }

    async function enteringPage() {
      try {
        const result = await HTTP.get('/auth/signupPageEnter');
      } catch (e) {
        console.log(e);
      }
    }

    function loginClick() {
      router.push({name: "sign-in-page"});
    }

    onMounted(() => {
      enteringPage();

      let utmSource = (route.query.utm_source ?? '').toString();
      let utmMedium = (route.query.utm_medium ?? '').toString();
      let utmCampaign = (route.query.utm_campaign ?? '').toString();

      localStorage.setItem('utm_source', utmSource);
      localStorage.setItem('utm_medium', utmMedium);
      localStorage.setItem('utm_campaign', utmCampaign);

      window.onbeforeunload = function () {
        localStorage.removeItem(signUpEmail);
      };
      setDefaults();

      watchEffect(() =>  {
        if (settings.stepMode === steps.changeRiskifiedEmail) return;

        let gtmFlowStage = "";
        switch (settings.stepMode) {
          case steps.checkPass:
            gtmFlowStage = "email_and_password";
            break;
          case steps.verifyCode:
            gtmFlowStage = "email_verification";
            break;
          default:
            break;
        }

        const relatedDataGTM = {
          flow: 'sign_up',
          stage: gtmFlowStage,
          'system-view': 'sign_up_' + gtmFlowStage
        };

        window.dataLayer?.push({ ...DataGTM, ...relatedDataGTM });
      });
    });

    return {
      signUp,
      useAuthProvider,
      continuePassword,
      continueRiskifyEmail,
      continueWithChosenEmail,
      setDefaults,
      loginClick,
      sendVerificationCode,
      verifyUser,
      onSubscribeNewsUpdatesChange,
      user,
      riskified,
      steps,
      settings,
      userDataForm,
      passContainsNumbers,
      passContainsCapitalLetters,
      passHasMinLength,
      passEqualsConfirmPass,
      passValidSymbols,
      emailForm,
      riskifiedForm,
      confirmPassForm,
      companyDataForm,
      verifyCodeForm,
      errorMessage,
      errorMessageProvider,
      showErrorMessageCode,
      loginHref,
      fieldsChanged,
      formSubtitle,
      isPasswordVisible,
      isPasswordValid,
      creationPurposes,
      creationPurposeValue,
      acceptTerms,
      riskyEmailMessage,
      verificationCodeResent,
      subscribeNewsUpdates,
      subscribeNewsUpdatesError,
    };
  }
});
