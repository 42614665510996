import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-vue-v3";

export function useFingerprint() {
  const fpVisitorData = useVisitorData(
    { extendedResult: true },
    { immediate: false }
  );

  async function getRequestId(): Promise<string | null> {
    await fpVisitorData.getData({ ignoreCache: true });
    const requestId = fpVisitorData.data?.value?.requestId ?? null;

    //TODO: We need a decision for the case when the Fingerprint library is blocked by a browser extension on the frontend

    // if (fpVisitorData.error.value || !requestId) {
    //   throw new Error("Security error");
    // }

    return requestId;
  }

  return {
    getRequestId
  };
} 