import axios from "axios";

import type { HttpResponse } from "@/types/http";

export enum RequestMethods {
  GET = "get",
  POST = "post"
}

const axiosConfig = {};

const handleResponse = <T extends HttpResponse>(response: any): T => {
  if (response?.status === 200)
    return { error: false, data: response?.data || null, message: "" } as T;

  return {
    error: true,
    message: response?.data?.errors || response?.data?.error?.message || '',
    data: null
  } as T;
};

const http = axios.create(axiosConfig);


const httpRequest = async <T extends HttpResponse>(
  method: RequestMethods,
  url: string,
  data?: any
): Promise<T> => {
  try {
    const res = await http[method](url, data);

    return handleResponse(res) as T;
  }
  catch (error: unknown) {
    console.log(error);
    return { error: true, data: null, message: "" } as T;
  }
};

export const httpGet = async <T extends HttpResponse>(url: string, params: Record<string, any> = {}): Promise<T> => {
  return await httpRequest(RequestMethods.GET, url, { params });
};

export const httpPost = async <T extends HttpResponse>(url: string, data?: any): Promise<T> => {
  return await httpRequest(RequestMethods.POST, url, data);
};
