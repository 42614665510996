import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

import { RouteNames } from "@/consts/router";
import externalPages from "@/router/external";

import SignIn from "@/components/auth/SignIn.vue";
import SignUp from "@/components/auth/SignUp.vue";
import SignUpConfirmData from "@/components/auth/SignUpConfirmData.vue";
import CreateHr from "@/components/platform/CreateHr.vue";
import ResetPassword from "@/components/auth/ResetPassword.vue";
import ConfirmData from "@/components/auth/ConfirmData.vue";
import PlatformIndex from "@/components/platform/PlatformIndex.vue";
import CreateSupplier from "@/components/platform/CreateSupplier.vue";
import SignupCreateHrSystem from "@/components/auth/SignupCreateHrSystem.vue";
import ClearLocalView from "@/views/ClearLocalView.vue";
import CallbackPage from "@/views/CallbackPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "index",
    redirect: () => {
      return { path: '/login' }
    },
  },
  {
    path: "/callback",
    name: "callback",
    component: CallbackPage,
    meta: {
      public: true,
      disableIfLoggedIn: false
    }
  },
  {
    path: "/login",
    name: "sign-in-page",
    component: SignIn,
    props: route => ({ code: route.query.code ?? null, state: route.query.state ?? null }),
    meta: {
      public: true,
      disableIfLoggedIn: true
    }
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: PlatformIndex,
    meta: {
      public: false
    }
  },
  {
    path: "/confirm-data",
    name: "confirm-data",
    component: ConfirmData,
    meta: {
      public: false
    }
  },
  {
    path: "/create-hr-system",
    name: "create-hr-system",
    component: CreateHr,
    meta: {
      public: false
    }
  },
  {
    path: "/create-supplier",
    name: "create-supplier-system",
    component: CreateSupplier,
    meta: {
      public: false
    }
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: SignUp,
    meta: {
      public: true,
      disableIfLoggedIn: true,
    }
  },
  {
    path: "/sign-up/confirm-data",
    name: "sign-up-confirm-data",
    component: SignUpConfirmData,
    meta: {
      public: false,
    }
  },
  {
    path: "/sign-up/create-hr-system",
    name: "signup-create-hr-system",
    component: SignupCreateHrSystem,
    meta: {
      public: false,
    }
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: ResetPassword,
    meta: {
      public: true,
      disableIfLoggedIn: true,
    }
  },
  {
    path: "/create-password",
    name: "create-password",
    component: ResetPassword,
    meta: {
      public: true,
      disableIfLoggedIn: true,
    }
  },
  {
    path: "/invite",
    name: "invite",
    component: ResetPassword,
    meta: {
      public: true,
      disableIfLoggedIn: true
    }
  },
  {
    path: "/clear",
    name: "ClearLocal",
    component: ClearLocalView,
    meta: {
      public: true,
      disableIfLoggedIn: false
    }
  },
  ...externalPages
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  if ([RouteNames.INVITATION].includes(to.name as RouteNames)) {
    next();
  }

  if (window.location.href.includes("create-from-template")) {
    const start = window.location.href.indexOf("/create-from-template/");
    const templateName = window.location.href.substring(start + 22);
    const systemName = localStorage.getItem("hrSystemName");
    if (systemName) {
      window.location.href =
        "/systems/" +
        systemName +
        "/v#/add-campaign/add-from-template-step2/" +
        templateName;
      return;
    } else {
      localStorage.setItem("templateName", templateName);
    }
  }



  const authData = localStorage.getItem("auth-data");
  let authToken = null;
  if (authData) {
    const parsedAuthData = JSON.parse(authData);
    authToken = parsedAuthData.token;
  }
  if (!to.meta.public) {
    if (authToken) {
      next();
    } else {
      next({ name: "sign-in-page" });
    }
  }

  if (to.meta.disableIfLoggedIn && authToken) {
    next({ name: "dashboard", query: to.query });
  }

  next();
});

export default router;
