import {defineComponent} from "vue";
import * as yup from "yup";
import {defineRule} from 'vee-validate';

defineRule("required", (value: any) => {
    if (!value) {
        return "This is required";
    }
    return true;
});
defineRule("requiredPass", (value: any) => {
    if (!value) {
        return "Think of a unique password";
    }
    return true;
});
defineRule("requiredPassCurrent", (value: any) => {
    if (!value) {
        return "Enter your password";
    }
    return true;
});
defineRule("requiredFirstName", (value: any) => {
    if (!value) {
        return "Enter your first name";
    }
    return true;
});
defineRule("passwordSymbols", (value: any) => {
    if (!/^(([a-zA-Z0-9])|([~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>?./]))*$/.test(value)) {
        return "Only latin letters, numbers and special symbols are allowed. <br />No spaces.";
    }
    return true;
});
defineRule("requiredCode", (value: any) => {
    if (!value) {
        return "Enter code";
    }
    return true;
});

defineRule("requiredLastName", (value: any) => {
    if (!value) {
        return "Enter your last name";
    }
    return true;
});
defineRule("requiredPhone", (value: any) => {
    if (!value) {
        return "Enter your phone number";
    }
    if (value.length < 5 || !/^[+]?\d+$/.test(value)) {
        return "Please enter a valid phone number";
    }
    return true;
});
defineRule("requiredCompanyName", (value: any) => {
    if (!value) {
        return "Enter company name";
    }
    return true;
});
defineRule("confirmed", (value: any, [other]: any) => {
    if (value !== other) {
        return `Passwords do not match`;
    }
    return true;
});

defineRule("numOfEmployees", (value: any) => {
    if (!value || value <= 0) {
        return "Enter number of employees";
    }
    return true;
});

defineRule('email', (value: any) => {
    let errorMessage = "Is there a typo? Let's try it again.";

    // Field is empty, should pass
    value = value.replace(/ /g, '')
    if (!value || !value.length) {
        errorMessage = "Email address is required";
        return errorMessage;
    }

    // Check if email
    if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
        return errorMessage;
    }

    return true;
});

//@ts-ignore
defineRule('strongPass', (value: any) => {
    let isValid = true;
    if (!value) {
        isValid = false;
    }
    if (!value.length) {
        isValid = false;
    }

    if (!/\d+/.test(value)) {
        isValid = false;
    }

    if (!/[A-Z]+/.test(value)) {
        isValid = false;
    }

    if (!/[a-z]+/.test(value)) {
        isValid = false;
    }

    return isValid ? isValid : 'Your password must be at least 8 characters long include at least one capital letter, and one number';
});

export default defineComponent({
    data() {
        return {
            validationRules: {
                email: yup.string().required().email(),
                required: yup.string().required(),
                number: yup.number().typeError('Value must be a number').required(),
                positive: yup.number().typeError('Value must be a number').required().min(1)
            }
        }
    }
})
