import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withKeys as _withKeys, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "page-content platform-page" }
const _hoisted_2 = { class: "container position-relative" }
const _hoisted_3 = { class: "content-title text-center" }
const _hoisted_4 = { class: "systems-list" }
const _hoisted_5 = ["onClick", "onKeyup"]
const _hoisted_6 = { class: "system-item__col-start" }
const _hoisted_7 = { class: "system-item__icon" }
const _hoisted_8 = { class: "system-item__col-main" }
const _hoisted_9 = { class: "system-item__name" }
const _hoisted_10 = { class: "system-item__type" }
const _hoisted_11 = { class: "system-item__col-end" }
const _hoisted_12 = { class: "system-item system-item--create" }
const _hoisted_13 = { class: "system-item__col-start" }
const _hoisted_14 = { class: "system-item__icon" }
const _hoisted_15 = { class: "system-item__col-main" }
const _hoisted_16 = { class: "system-item__name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_InfoBlock = _resolveComponent("InfoBlock")!
  const _component_IconSystem = _resolveComponent("IconSystem")!
  const _component_IconArrowRight = _resolveComponent("IconArrowRight")!
  const _component_IconProfile = _resolveComponent("IconProfile")!
  const _component_UiPreloader = _resolveComponent("UiPreloader")!
  const _component_UiPreloaderContainer = _resolveComponent("UiPreloaderContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header, {
      systems: _ctx.systems,
      "user-email": _ctx.user.mail,
      "account-info": "",
      onSwitchSystem: _ctx.redirect
    }, null, 8, ["systems", "user-email", "onSwitchSystem"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("platform.title")), 1),
      (!_ctx.user.emailVerified || (_ctx.user.emailVerified && _ctx.userConfirmNow))
        ? (_openBlock(), _createBlock(_component_InfoBlock, {
            key: 0,
            status: _ctx.user.emailVerified ? 'success' : 'error'
          }, null, 8, ["status"]))
        : _createCommentVNode("", true),
      _createElementVNode("ul", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.systems, (system, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: `system-${index}`,
            class: "system-item"
          }, [
            _createElementVNode("div", {
              class: "system-item__row",
              role: "button",
              onClick: ($event: any) => (_ctx.redirect(system, true)),
              onKeyup: [
                _withKeys(($event: any) => (_ctx.redirect(system, true)), ["enter"]),
                _withKeys(($event: any) => (_ctx.redirect(system, true)), ["space"])
              ],
              tabindex: "0"
            }, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_IconSystem)
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, _toDisplayString(system.title), 1),
                _createElementVNode("div", _hoisted_10, _toDisplayString(system.type === "system"
                      ? _ctx.$t("text.typeSystemTitle")
                      : _ctx.$t("text.typeSupplierTitle")), 1)
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_IconArrowRight)
              ])
            ], 40, _hoisted_5)
          ]))
        }), 128)),
        _createElementVNode("li", _hoisted_12, [
          _createElementVNode("div", {
            class: "system-item__row",
            role: "button",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addAccount && _ctx.addAccount(...args))),
            onKeyup: [
              _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.addAccount && _ctx.addAccount(...args)), ["enter"])),
              _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.addAccount && _ctx.addAccount(...args)), ["space"]))
            ],
            tabindex: "0"
          }, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_IconProfile)
              ])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t("platform.systemCreation")), 1)
            ])
          ], 32)
        ])
      ]),
      (!_ctx.loaded)
        ? (_openBlock(), _createBlock(_component_UiPreloaderContainer, {
            key: 1,
            class: "bg-white",
            absolute: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_UiPreloader)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}