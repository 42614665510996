
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "InfoBlock",
  props: ["status"],
  setup(props) {
    const { t } = useI18n();

    const info = computed(() => {
      const infoObj = {
        cardClasses: "",
        systemInfoClasses: "",
        iconColor: "#9499A5",
        text: ""
      };

      if (props.status === "error") {
        infoObj.cardClasses = "card--system-info-error";
        infoObj.systemInfoClasses = "system-info--error";
        infoObj.iconColor = "#e61e4e";
        infoObj.text = t("info.emailNotConfirmed")
      } else if (props.status === "success") {
        infoObj.cardClasses = "card--system-info-success";
        infoObj.systemInfoClasses = "system-info--success";
        infoObj.iconColor = "#53aaa5";
        infoObj.text = t("info.emailConfirmed")
      }

      return infoObj;
    })

    return {
      info
    };
  }
});
