import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, withModifiers as _withModifiers, withKeys as _withKeys, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c750806"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-code__fieldset" }
const _hoisted_2 = ["onUpdate:modelValue", "onInput", "onKeyup"]
const _hoisted_3 = { class: "btn-outer" }
const _hoisted_4 = {
  class: "btn btn--tertiary",
  type: "submit"
}
const _hoisted_5 = { class: "d-flex align-items-center" }
const _hoisted_6 = { class: "text-pseudo-link" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 1,
  class: "error error--static error-text text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createBlock(_component_Form, {
    onSubmit: _withModifiers(_ctx.submitHandler, ["prevent"]),
    class: "content-form form-code"
  }, {
    default: _withCtx(() => [
      _createElementVNode("fieldset", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.codeInputs, (input) => {
          return _withDirectives((_openBlock(), _createElementBlock("input", {
            key: input.index,
            "onUpdate:modelValue": ($event: any) => ((input.value) = $event),
            onFocusin: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isCodeValid = true)),
            onInput: ($event: any) => (_ctx.codeInputHandler(input.index)),
            onKeyup: _withKeys(_withModifiers(($event: any) => (_ctx.backToPrevCodeInput(input.index)), ["exact"]), ["delete"]),
            ref_for: true,
            ref: `code-${input.index}`,
            class: "form-control form-code__code-input",
            type: "number",
            inputmode: "numeric"
          }, null, 40, _hoisted_2)), [
            [_vModelText, input.value]
          ])
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", _hoisted_4, _toDisplayString(_ctx.$t("button.enter")), 1)
      ]),
      _createElementVNode("p", _hoisted_5, [
        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("text.didNotReceiveCode")), 1),
        _createElementVNode("button", {
          class: "button-pseudo-link",
          type: "button",
          onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.resendCodeHandler && _ctx.resendCodeHandler(...args)), ["stop"]))
        }, _toDisplayString(_ctx.$t("text.resendHyphen")), 1)
      ]),
      (_ctx.codeResent)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("b", null, _toDisplayString(_ctx.$t("text.verificationCodeResent")), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.showErrorMessage)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t("text.pleaseEnterCode")), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onSubmit"]))
}