import {HTTP} from "@/main";

const Helpers = {
    updateUser(token: string, phone: string) {
        return HTTP.post('auth/user/update', {
            phone: phone
        }, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        });

    },
    loadSystems(token: string) {
        return HTTP.get('/platform/v1/index', {
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
    },
    generateToken(system: any, token: string) {
        if (system.type === "system") {
            return HTTP.get("auth/token/system/" + system.systemName, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }).then(
                (response: any) => {
                    localStorage.setItem(
                        "ember_simple_auth:session",
                        JSON.stringify(response.data)
                    );
                    localStorage.setItem('hrSystemName', system.systemName);
                    localStorage.setItem("hr-system-entrance", "true");
                    //@ts-ignore
                    return window.location.origin + "/systems/" + system.systemName + '/v#/dashboard';
                }
            );
        }

        if (system.type === "supplier") {
            return HTTP.get("auth/token/" + system.type + "/" + system.systemName, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }).then(
                (response: any) => {
                    localStorage.setItem(
                        "buyme-supplier:session",
                        JSON.stringify({
                            isAuthenticated: true,
                            authenticated: response.data
                        })
                    );
                    //@ts-ignore
                    return window.location.origin + "/supplier"
                }
            );
        }
    }
}

export default Helpers;