
import {
  defineComponent,
  ref,
  onMounted,
  reactive,
  watch,
  onBeforeMount
} from "vue";
import { Providers } from "universal-social-auth";
import helpers from "@/helpers";
import { Form, Field } from "vee-validate";
import { useI18n } from "vue-i18n";
import { HTTP } from "@/main";
import { useRoute, useRouter } from "vue-router";
import Header from "@/components/Header.vue";
import axios from "axios";
import UiPreloaderContainer from "@/components/ui-components/preloader/UiPreloaderContainer.vue";
import UiPreloader from "@/components/ui-components/preloader/UiPreloader.vue";
import {DataGTM} from "@/consts/gtm";
import { useFingerprint } from "@/composables/useFingerprint";
import type {AxiosError} from "axios";
import type {SocialCallbackResponse} from "@/types/sign-in";
import {
  getOktaLink,
  getAzureLink,
  getUserInfoByOkta,
  getUserInfoByAzure
} from "@/services/sign-in.service";
import IconOkta from "@/components/icons/IconOkta.vue";
import IconMicrosoft from "@/components/icons/IconMicrosoft.vue";

export default defineComponent({
  name: "SignIn",
  components: {
    IconMicrosoft,
    IconOkta,
    Form,
    Field,
    Header,
    UiPreloaderContainer,
    UiPreloader
  },
  props: {
    code: {
      type: String,
      default: null,
    },
    state: {
      type: String,
      default: null
    }
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const emailConfirmed = ref(true);
    const loggedInViaSocial = ref(false);
    const signInEmail = "signIn-email";
    const steps = {
      default: "default",
      checkPass: "checkPass",
      checkCode: "checkCode",
      resetPass: "resetPass"
    };
    const settings = reactive({
      stepMode: "checkPass",
      socialVisible: true,
      isDataProcessing: true
    });
    const fieldsChanged = reactive({
      formCheckPassword: false,
      formResetPassword: false,
      formCheckSecretCode: false
    });
    const { t } = useI18n();
    const checkEmailForm = ref(null);
    const checkPasswordForm = ref(null);
    const wrongSecretCodeCounter = ref(0);
    const wrongSecretCodeLimit = ref(3);
    const checkSecretCodeForm = ref(null);
    const resetPasswordForm = ref(null);
    const secretCodeSentTo = ref("");
    const errorMessage = ref(null);
    const saveMe = ref(false);
    const secretCodeSaved = ref(false);
    const email = ref("");
    const password = ref("");
    const secretCode = ref(null);
    const resetPasswordSent = ref(false);

    const { getRequestId } = useFingerprint();

    watch(
        () => settings.stepMode,
        () => {
          errorMessage.value = null;
        }
    );

    function checkEmail() {
      const verifiedEmail = localStorage.getItem(signInEmail);
      if (verifiedEmail) {
        email.value = verifiedEmail;
        settings.stepMode = steps.checkPass;
        settings.socialVisible = false;
      }
    }

    async function confirmUserInHrSystem() {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");
      if (!token) return;

      try {
        const response = await axios.post("/auth/confirmUserInHrSystem", {
          token
        });
        email.value = response.data.email;
      } catch {
        return;
      }
    }

    async function confirmUser() {
      const token = route.query.confirmToken;
      if (!token) {
        return;
      }

      try {
        settings.isDataProcessing = true;
        await HTTP.get("/auth/register/confirm/" + token);
        emailConfirmed.value = true;
        const { data } = await HTTP.post("auth/login/token", {
          confirmToken: token
        });
        localStorage.removeItem(signInEmail);
        localStorage.setItem("auth-data", JSON.stringify(data));
        localStorage.setItem("need-to-reload", "true");
        localStorage.setItem("first-login", "true");
        await redirectAfterLogin(data);
      } catch (error) {
        // @ts-ignore
        if (error.response.status === 422) {

          const query = { ...route.query };
          delete query["confirmToken"];
          router.replace({ name: "sign-in-page", query });
        } else {
          errorHandle(error);
        }
        settings.isDataProcessing = false;
      }
    }

    function removeReadOnly(event: any) {
      event.preventDefault()
      if (event.target.getAttribute('readonly') === '') {
        event.target.removeAttribute('readonly')
      }
    }

    async function sendResetPass() {
      fieldsChanged.formResetPassword = false;

      try {
        errorMessage.value = null;
        //@ts-ignore
        const {valid} = await resetPasswordForm.value.validate();
        if (valid) {
          settings.isDataProcessing = true;
          await axios.post("/auth/sendResetPassword", {
            email: email.value
          });
          resetPasswordSent.value = true;
          settings.isDataProcessing = false;
        }
      } catch (error) {
        //@ts-ignore
        if (error.response.status === 401) {
          emailConfirmed.value = false;
          await sendConfirmEmail();
        }
        errorHandle(error);
        settings.isDataProcessing = false;
      }
    }

    function backDefault() {
      settings.stepMode = steps.default;
      settings.socialVisible = true;
    }

    function backToLogin() {
      settings.stepMode = "checkPass";
      fieldsChanged.formResetPassword = true;
    }

    async function sendSecretCode() {
      settings.isDataProcessing = true;
      const {data} = await HTTP.post("/auth/sendSecretCode", {
        username: email.value
      });
      switch (data.sentTo) {
        case 'email':
          secretCodeSentTo.value = 'xxxx' + data.destination.substring(data.destination.indexOf("@") - 2);
          break;
        case "phone":
          secretCodeSentTo.value = "xxx-xxx-" + data.destination.substring(data.destination.length - 4);
          break;
      }
      settings.isDataProcessing = false;
      return data;
    }

    async function login() {
      fieldsChanged.formCheckSecretCode = false;

      try {
        errorMessage.value = null;

        const requestId = await getRequestId();

        //@ts-ignore
        const {valid} = await checkSecretCodeForm.value.validate();
        if (!valid && !secretCodeSaved.value) {
          return;
        }

        settings.isDataProcessing = true;
        let rememberThirtyDays = '';

        if (saveMe.value) {
          rememberThirtyDays = String(Date.now());
          localStorage.setItem("code-checked-" + email.value, String(Date.now()));
        }
        const {data} = await HTTP.post("/auth/login", {
          secret_code: secretCode.value,
          username: email.value,
          password: password.value,
          rememberThirtyDays,
          requestId
        });

        if (data.length) {
          settings.isDataProcessing = false;
        }

        localStorage.removeItem(signInEmail);
        localStorage.setItem("auth-data", JSON.stringify(data));
        localStorage.setItem("need-to-reload", "true");
        localStorage.setItem("first-login", "true");
        localStorage.setItem("login-completed", "true");

        await redirectAfterLogin(data);
      } catch (error) {
        console.log('error', error);
        settings.isDataProcessing = false;
        //@ts-ignore
        if (error.response.status === 401) {
          emailConfirmed.value = false;
          await sendConfirmEmail();
          //@ts-ignore
        } else if (error.response.status === 422) {
          wrongSecretCodeCounter.value += 1;
          //@ts-ignore
          errorMessage.value = t("text.wrongSecretCode", {
            wrongAttepmtsCount: wrongSecretCodeCounter.value,
            wrongAttepmtsLimit: wrongSecretCodeLimit.value
          });
          if (wrongSecretCodeCounter.value == 3) {
            settings.stepMode = steps.checkPass;
            errorMessage.value = null;
            email.value = "";
            password.value = "";
            secretCode.value = null;
            wrongSecretCodeCounter.value = 0;
            //@ts-ignore
            checkPasswordForm.value.resetForm();
            //@ts-ignore
            checkSecretCodeForm.value.resetForm();
          }
        } else {
          errorHandle(error);
        }
      }
    }

    async function redirectAfterLogin(user: any) {
      const token = user.token;
      const systems = await helpers.loadSystems(token);

      if (!systems.data.length) {
        if (loggedInViaSocial.value) {
          localStorage.setItem("registered-via-social", "true");
        }
        let utmSource = (route.query.utm_source ?? "").toString();
        let utmMedium = (route.query.utm_medium ?? "").toString();
        let utmCampaign = (route.query.utm_campaign ?? "").toString();

        localStorage.setItem("utm_source", utmSource);
        localStorage.setItem("utm_medium", utmMedium);
        localStorage.setItem("utm_campaign", utmCampaign);
        localStorage.setItem("emptySystemsList", "true");

        if (systems.data?.code === 76) { // 76 - CODE_NOT_FINISHED_REGISTRATION_NAME
          await router.push({name: "sign-up-confirm-data"});
        }
        if (systems.data?.code === 77) { // 76 - CODE_NOT_FINISHED_REGISTRATION_NAME
          document.location.replace(window.location.origin + "/gifting-platform#/sign-up/create-hr-system");
        }
        return;
      }

      if (!user.user.emailVerified) {
        window.location.replace(
            window.location.origin + "/gifting-platform#/dashboard"
        );
      }

      if (systems.data.length > 1) {
        window.location.replace(
            window.location.origin + "/gifting-platform#/dashboard"
        );
      } else {
        const redirectUrl = await helpers.generateToken(systems.data[0], token);
        localStorage.setItem("hr-system-entrance", "true");
        //@ts-ignore
        window.location.replace(redirectUrl);
      }
    }

    function socialLoginCallback(response: SocialCallbackResponse): void {
      localStorage.setItem("auth-data", JSON.stringify(response.data));
      localStorage.removeItem(signInEmail);
      localStorage.setItem("need-to-reload", "true");
      localStorage.setItem("first-login", "true");

      loggedInViaSocial.value = true;

      localStorage.setItem("login-completed", "true");

      redirectAfterLogin(response.data);
    }

    async function useAuthProvider(provider: any, proData: any) {
      const pro = proData;
      const ProData = pro || Providers[provider];
      const requestId = await getRequestId();

      // @ts-ignore
      this.$oauth
          .authenticate(provider, ProData)
          .then((response: any) => {
            if (response.code) {
              settings.isDataProcessing = true;
              HTTP.post("/auth/social/" + provider, {
                // @ts-ignore
                code: response.code,
                requestId
              })
                  .then(socialLoginCallback)
                  .catch((error: any) => {
                    errorMessage.value = error.response.data.message;
                    settings.isDataProcessing = false;
                  });
            }
          })
          .catch((err: any) => {
            errorMessage.value = err.response.data.message;
          });
    }

    async function continueEmail() {
      settings.isDataProcessing = true;
      try {
        email.value = email.value.replace(/ /g, "");
        errorMessage.value = null;
        //@ts-ignore
        const {valid} = await checkEmailForm.value.validate();
        if (valid) {
          const {data} = await HTTP.post("/auth/isRegistered", {
            username: email.value
          });

          if (data) {
            settings.isDataProcessing = false;
          }

          if (!data.isRegistered) {
            // @ts-ignore
            localStorage.setItem("signUp-email", email.value);
            router.replace({name: "sign-up"});
          } else {
            localStorage.setItem(signInEmail, email.value);
            settings.stepMode = steps.checkPass;
            settings.socialVisible = false;
          }
        } else {
          settings.isDataProcessing = false;
        }
      } catch (error) {
        settings.isDataProcessing = false;
        errorHandle(error);
      }
    }

    async function continuePassword() {
      fieldsChanged.formCheckPassword = false;

      try {
        errorMessage.value = null;
        //@ts-ignore
        const {valid} = await checkPasswordForm.value.validate();

        if (valid) {
          settings.isDataProcessing = true;
          const isRegisteredResponse = await HTTP.post("/auth/isRegistered", {
            username: email.value
          });

          if (!isRegisteredResponse.data.isRegistered) {
            localStorage.setItem("signUp-email", email.value);
            router.replace({name: "sign-up"});
          }
          const {data} = await HTTP.post("/auth/check-pass", {
            username: email.value,
            password: password.value
          });
          // prettier-ignore
          const codeChecked = localStorage.getItem("code-checked-" + email.value);
          if (data.isValid) {
            if (
                (codeChecked && ((Date.now() - Number(codeChecked)) / 86400000) < 30) ||
                data.isLoginAllowed
            ) {
              secretCodeSaved.value = true;
              await login();
            } else {
              await sendSecretCode();
              settings.isDataProcessing = false;
              settings.stepMode = steps.checkCode;
            }
          } else {
            //@ts-ignore
            errorMessage.value =
                "Wrong password. <br />Try again or click Forgot password to reset it.";
            settings.isDataProcessing = false;
          }
        }
      } catch (error) {
        //@ts-ignore
        if (error.response.status === 401) {
          emailConfirmed.value = false;
          await sendConfirmEmail();
        } else {
          errorHandle(error);
        }
        settings.isDataProcessing = false;
      }
    }

    async function sendConfirmEmail() {
      settings.isDataProcessing = true;
      try {
        await axios.post("/auth/sendConfirmEmail", {
          email: email.value
        });
        settings.isDataProcessing = false;
      } catch (error) {
        // @ts-ignore
        if (error.response.status === 403) errorMessage.value = error.response.data.message;
        settings.isDataProcessing = false;
      }
    }

    async function loginByOkta() {
      try {
        settings.isDataProcessing = true;

        const response = await getOktaLink();

        if (response.data?.url) {
          localStorage.setItem("social-auth-type", "okta");
          window.location.assign(response.data.url);
        }

        settings.isDataProcessing = false;
      } catch (error) {
        settings.isDataProcessing = false;
        console.error(error);
      }
    }

    async function loginByAzure() {
      try {
        settings.isDataProcessing = true;

        const response = await getAzureLink();

        if (response.data?.url) {
          localStorage.setItem("social-auth-type", "azure");
          window.location.assign(response.data.url);
        }

        settings.isDataProcessing = false;
      } catch (error) {
        settings.isDataProcessing = false;
        console.error(error);
      }
    }

    function continueWithPass() {
      settings.stepMode = steps.checkPass;
      settings.socialVisible = false;
    }

    function continueWithEmail() {
      if (settings.isDataProcessing) return;
      settings.stepMode = steps.default;
    }

    function resetPass() {
      if (settings.isDataProcessing) return;
      fieldsChanged.formCheckPassword = true;
      settings.stepMode = steps.resetPass;
    }

    function errorHandle(error: any) {
      if (!error.response) {
        errorMessage.value = error.message;
        return;
      }
      if (error.response.status === 500) {
        //@ts-ignore
        errorMessage.value = "Something went wrong";
      } else {
        //@ts-ignore
        errorMessage.value = error.response.data.message;
      }
    }

    onMounted(() => {
      window.dataLayer = window.dataLayer || [];

      const logoutDataGtm = JSON.parse(localStorage.getItem("logout-data-gtm") ?? "{}");

      if (Object.keys(logoutDataGtm).length) {
        window.dataLayer?.push({ ...DataGTM, ...logoutDataGtm });
        localStorage.removeItem("logout-data-gtm");
      }

      const relatedDataGTM = {
        flow: 'login',
        stage: 'page',
        'system-view': 'login_page',
      };

      window.dataLayer?.push({ ...DataGTM, ...relatedDataGTM });

      window.onbeforeunload = function () {
        localStorage.removeItem(signInEmail);
      };
      // prettier-ignore
      document.addEventListener("backbutton", function () {
        localStorage.removeItem(signInEmail);
      }, false);
      confirmUser();
      checkEmail();
    });

    onBeforeMount(() => {
      confirmUserInHrSystem();
    });

    (async () => {
      const socialAuthType: string = localStorage.getItem("social-auth-type") || "";
      const authMethods = {
        okta: getUserInfoByOkta,
        azure: getUserInfoByAzure
      };

      if (!props.code || !props.state || !Object.prototype.hasOwnProperty.call(authMethods, socialAuthType)) {
        settings.isDataProcessing = false;

        return;
      }

      try {
        const response = await authMethods[socialAuthType as keyof typeof authMethods]({
          code: props.code,
          state: props.state
        });

        socialLoginCallback(response);
        settings.isDataProcessing = false;
      } catch (error) {
        errorMessage.value = (error as AxiosError)?.response?.data?.error || "";
        settings.isDataProcessing = false;
      }
    })();

    return {
      useAuthProvider,
      login,
      continueEmail,
      continueWithEmail,
      continuePassword,
      removeReadOnly,
      sendResetPass,
      checkEmail,
      confirmUserInHrSystem,
      continueWithPass,
      sendConfirmEmail,
      resetPass,
      backDefault,
      sendSecretCode,
      backToLogin,
      loginByOkta,
      loginByAzure,
      steps,
      settings,
      email,
      secretCode,
      password,
      checkPasswordForm,
      checkEmailForm,
      resetPasswordForm,
      checkSecretCodeForm,
      errorMessage,
      resetPasswordSent,
      secretCodeSentTo,
      saveMe,
      emailConfirmed,
      fieldsChanged
    };
  }
});
