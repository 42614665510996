const PRELOADER_LOTTIE_FILE_URL = '/lottie/preloader.lottie';

export const fetchPreloaderData = async (): Promise<ArrayBuffer | null> => {
    try {
        const response = await fetch(PRELOADER_LOTTIE_FILE_URL);
        if (!response.ok) {
            throw new Error('Failed to fetch Lottie file');
        }

        const arrayBuffer = await response.arrayBuffer();
        return arrayBuffer;
    } catch (error) {
        console.error('Error fetching preloader data:', error);
        return null;
    }
};
