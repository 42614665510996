<template>
  <ul class="password-rules">
    <slot></slot>
  </ul>
</template>

<style lang="scss" scoped>
.password-rules {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin: 15px 0;
}
</style>