export const redirectWithCorrectQueryParams = (): void => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    const state = params.get('state');

    params.delete('code');
    params.delete('state');


    if (code && state) {
        const queryParams = `?code=${code}&state=${state}`;
        const newUrl = `${url.origin}${url.pathname}${url.hash}${queryParams}`;
        console.log("newUrl", newUrl);

        window.location.href = newUrl;
    }
}