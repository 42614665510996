export enum HrSystemCreationFlow {
    SignUpDefault = "sign-up-default",
    SignUpSocial = "sign-up-social",
    LoggedIn = "logged-in",
    LoggedInNoSystems = "logged-in-no-systems",
    ConfirmData = "confirm-data"
};

export enum Screens {
    PersonalData = "personal-data",
    CompanyData = "company-data"
};

export const BackgroundImage = '/images/sign-up-completion/screenshot-hr-cabinet.png';

export const InfoImages = [
    '/images/sign-up-completion/image01.png',
    '/images/sign-up-completion/image02.png',
    '/images/sign-up-completion/image03.png',
    '/images/sign-up-completion/image04.png',
];

export const InfoImage = '/images/sign-up-completion/image.png';

export const InfoImageTexts = {
    top: "150+ countries",
    bottom: "3500 brands"
};

export const CreationPurposes = [
    'Employee recognition',
    'Customers',
    'Sales prospects',
    'Other'
];
