
import { defineComponent, ref, onMounted, reactive, computed } from "vue";
import { useRouter } from "vue-router";
import { gtmDateTimestamp } from "@/helpers/date";

export default defineComponent({
  name: "PageHeader",
  props: {
    accountInfo: {
      type: Boolean,
      default: false
    },
    closeIcon: {
      type: Boolean,
      default: false
    },
    closeIconHandlerName: {
      type: String,
      default: ""
    },
    systems: {
      type: Array,
      default: []
    },
    userEmail: {
      type: String,
      default: ""
    }
  },
  emits: ["switch-system", "close-icon-handler"],
  setup(props, { emit }) {
    const router = useRouter();
    const systemPrefix = localStorage.getItem("hrSystemName");
    const isAccountDropdownVisible = ref(false);
    const searchWord = ref("");
    const userData = reactive({
      firstLetters: "",
      name: "",
      email: ""
    });

    const filteredSystems = computed(() => {
      return props.systems.filter((item: any) => {
        return item.systemName.toLowerCase().includes(searchWord.value.toLowerCase());
      });
    })

    onMounted(() => {
      if (props.accountInfo) {
        userData.email = props.userEmail;
        // @ts-ignore
        // prettier-ignore
        const username = JSON.parse(localStorage.getItem("auth-data"))?.username || "";
        if (username && username.length) {
          userData.name = username;
          // prettier-ignore
          userData.firstLetters = username.split(" ").map((word: any) => word[0]).join('');
        }
      }
    });

    function homeRedirect() {
      window.location.replace(window.location.origin)
    }

    function logout() {
      const authData = JSON.parse(localStorage.getItem("auth-data") ?? "{}");
      const systemUrl = window.location.href;

      const relatedDataGTM = {
        flow: 'logout',
        stage: 'completed',
        'user-id': authData?.user.uid + '' || '',
        'system-url': systemUrl,
        'user-creation-date': authData?.user?.created ? gtmDateTimestamp(authData?.user?.created) : '',
      };

      localStorage.setItem("logout-data-gtm", JSON.stringify({ ...relatedDataGTM }));

      localStorage.removeItem("auth-data");
      localStorage.removeItem("buyme-supplier:session");
      localStorage.removeItem("ember_simple_auth:session");
      localStorage.removeItem("available-systems");
      localStorage.removeItem('hrSystemName');

      router.replace({ name: "sign-in-page" });
    }

    function accountDropdown() {
      isAccountDropdownVisible.value = !isAccountDropdownVisible.value;
    }

    function onClickAway() {
      isAccountDropdownVisible.value = false;
    }

    function closeIconHandler() {
      if (!props.closeIconHandlerName.length) {
        localStorage.removeItem("signIn-email");
        localStorage.removeItem("signUp-email");
        localStorage.removeItem("auth-data");
        window.location.replace("/");
      } else if (props.closeIconHandlerName === "toDashboard") {
        router.push({ name: "dashboard" });
      }
    }

    function addAccount() {
      router.push({ name: "confirm-data" });
      // router.push({ name: "create-hr-system" });
    }

    function switchSystem(system: any) {
      emit("switch-system", system);
    }

    return {
      logout,
      accountDropdown,
      onClickAway,
      closeIconHandler,
      addAccount,
      switchSystem,
      homeRedirect,
      isAccountDropdownVisible,
      userData,
      systemPrefix,
      searchWord,
      filteredSystems,
    };
  }
});
