import type {
    OktaParams,
    AzureParams,
    OktaLinkResponse,
    AzureLinkResponse,
    SocialCallbackResponse
} from "@/types/sign-in";

import { HTTP } from "@/main";

const OKTA_URL = "/auth/oauth/okta";
const AZURE_URL = "/auth/oauth/azure";
const OKTA_LINK_URL = "/auth/oauth/okta/url";
const AZURE_LINK_URL = "/auth/oauth/azure/url";

export const getOktaLink = (): Promise<OktaLinkResponse> => {
    return HTTP.get(OKTA_LINK_URL);
};

export const getUserInfoByOkta = (params: OktaParams): Promise<SocialCallbackResponse> => {
    return HTTP.get(OKTA_URL, { params });
};

export const getAzureLink = (): Promise<AzureLinkResponse> => {
    return HTTP.get(AZURE_LINK_URL);
};

export const getUserInfoByAzure = (params: AzureParams): Promise<SocialCallbackResponse> => {
    return HTTP.get(AZURE_URL, { params });
};