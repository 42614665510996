import {redirectWithCorrectQueryParams} from "@/helpers/filter-query-params";

redirectWithCorrectQueryParams();

import {createApp} from "vue";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import page from "@/mixins/page";
import App from "./App.vue";
import {UniversalSocialauth} from "universal-social-auth";
import VueClickAway from "vue3-click-away";
import VueGtm from 'vue-gtm';

// Internationalization
import {createI18n} from "vue-i18n";
import enLocale from "./locales/en";
import heLocale from "./locales/he";

import { fpjsPlugin, FingerprintJSPro } from "@fingerprintjs/fingerprintjs-pro-vue-v3";

const appParams = document.querySelector("#appParams") as HTMLElement | null;
const gtmId = appParams ? appParams.dataset.gtm_id as string : '';

const i18n = createI18n({
    locale: "en",
    fallbackLocale: "en",
    messages: {
        en: enLocale,
        he: heLocale
    }
});

//Social auth
const hostName = window.location.hostname;
const protocol = window.location.protocol;
const OAuthProviders = {
    providers: {
        google: {
            clientId: process.env.VUE_APP_GOOGLE_USER_ID,
            redirectUri:
                protocol +
                "//" +
                hostName +
                "/" +
                process.env.VUE_APP_GOOGLE_CALLBACK_URL
        }
    }
};

const OAuth = new UniversalSocialauth(axios, OAuthProviders);

const axiosConfig: { headers?: any } = {};

const authData = localStorage.getItem('auth-data')
if (authData) {
    const token = (JSON.parse(authData)).token
    const authHeader = 'Bearer ' + token
    axiosConfig.headers = {
        "Authorization": authHeader
    };
}


export const HTTP = axios.create(axiosConfig)

const app = createApp(App)
  .mixin(page)
  .use(fpjsPlugin, {
    loadOptions: {
      apiKey: process.env.VUE_APP_FINGERPRINT_PUBLIC_KEY,
      endpoint: [
        process.env.VUE_APP_FINGERPRINT_ENDPOINT,
        FingerprintJSPro.defaultEndpoint
      ],
      scriptUrlPattern: [
        process.env.VUE_APP_FINGERPRINT_URL_PATTERN,
        FingerprintJSPro.defaultScriptUrlPattern
      ]
    }
  })
  .use(router)
  .use(i18n)
  .use(VueAxios, HTTP)
  .use(VueClickAway);

if (gtmId) {
    app.use(
        VueGtm, {
            id: gtmId,
            vueRouter: router,
        }
    );
}

app.config.globalProperties.$oauth = OAuth;
app.mount("#app");
