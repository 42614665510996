import { IDataGtm } from "@/types/gtm/data";

export const DataGTM: IDataGtm = {
    event: 'gifted-system', // the same for all tracking events
    flow: '', // any multiple steps process on the platform. examples-> campaign_creation, load_funds, create_template, swag_store, physical_gift_store
    stage: '', // the stage of the multiple steps process. examples-> completed, email, password, personal_info, select_gift_type, etc.
    value: '', // mainly for load_funds "completed" event. numeric with two decimal types.
    currency: '', // ISO 4217 three-letter code.
    'payment-method': '', // mainly for load_funds "completed" events. examples-> credit card, ACH, invoice.
    'transaction-id': '',
    items: '', // Array of items (products), relevant to "ecommerce purchases" only like on the swag_store and physical_gift_store. Array requirements can be found here --> https://docs.mixpanel.com/docs/data-structure/property-reference#list-of-objects. each product's dimensions will be defined later on or pulled from what you have on each product.
    'hr-system': '', // Dynamically replace the text between the #.
    'hr-system-id': '', // Dynamically replace the text between the #.
    'user-id': '', // Dynamically replace the text between the #.
    'system-url': window.location.href, // Dynamically replace the text between the #.
    'system-view': '', // Dynamically replace the text between the #. This should be the screen/pop-up the user sees, meaning the most front layer of the current view. For example on funds load process this should be the name of the pop-up screen and not the screen behind the pop-up.
    'system-action': '',
    'system-creation-date': '',
    'user-creation-date': '',
}