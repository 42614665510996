import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "system-info__inner d-flex" }
const _hoisted_2 = { class: "system-info__icon-outer" }
const _hoisted_3 = {
  class: "system-info__icon",
  width: "36",
  height: "36",
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_4 = ["stroke"]
const _hoisted_5 = ["stroke"]
const _hoisted_6 = ["stroke"]
const _hoisted_7 = { class: "system-info__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['card card--system-info', _ctx.info.cardClasses])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(['system-info', _ctx.info.systemInfoClasses])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(), _createElementBlock("svg", _hoisted_3, [
            _createElementVNode("path", {
              d: "M9.99996 18.3333C14.6023 18.3333 18.3333 14.6024 18.3333 9.99999C18.3333 5.39762 14.6023 1.66666 9.99996 1.66666C5.39759 1.66666 1.66663 5.39762 1.66663 9.99999C1.66663 14.6024 5.39759 18.3333 9.99996 18.3333Z",
              stroke: _ctx.info.iconColor,
              "stroke-width": "2",
              "stroke-linecap": "round",
              "stroke-linejoin": "round"
            }, null, 8, _hoisted_4),
            _createElementVNode("path", {
              d: "M10 13.3333V10",
              stroke: _ctx.info.iconColor,
              "stroke-width": "2",
              "stroke-linecap": "round",
              "stroke-linejoin": "round"
            }, null, 8, _hoisted_5),
            _createElementVNode("path", {
              d: "M10 6.66666H10.0083",
              stroke: _ctx.info.iconColor,
              "stroke-width": "2",
              "stroke-linecap": "round",
              "stroke-linejoin": "round"
            }, null, 8, _hoisted_6)
          ]))
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("b", null, _toDisplayString(_ctx.info.text), 1)
        ])
      ])
    ], 2)
  ], 2))
}