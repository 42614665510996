export const gtmDateTimestamp = (timestamp: number): string => {
    const date = new Date(timestamp*1000);

    if (isNaN(date.getTime())) return "";

    const dateMonth = date.getMonth() + 1;
    const month = dateMonth < 10 ? `0${dateMonth}` : String(dateMonth);

    return `${month}-${date.getFullYear()}`;
};

export const transformTimeWithAMPM = (date: string): string => {
  const dateObject = new Date(date);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  };

  return new Intl.DateTimeFormat('en-US', options)
    .format(dateObject)
    .replace(/,/g, '')
    .replaceAll("/", ".");
};
