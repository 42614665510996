
import { defineComponent } from "vue";
import { Form, Field } from "vee-validate";
import Header from "@/components/Header.vue";
import Multiselect from "@vueform/multiselect";
import { HTTP } from "@/main";
import UiPreloaderContainer from "@/components/ui-components/preloader/UiPreloaderContainer.vue";
import UiPreloader from "@/components/ui-components/preloader/UiPreloader.vue";

export default defineComponent({
  name: "CreateSupplier",
  components: {
    Form,
    Field,
    Header,
    Multiselect,
    UiPreloaderContainer,
    UiPreloader,
  },
  data() {
    return {
      buttonDisabled: false,
      countries: [],
      name: "",
      acceptTerms: false,
      country: 0,
      errorMessage: null as null | string,
      isDataProcessing: false,
      fieldsChanged: false
    };
  },
  mounted() {
    this.loadCountries();
  },
  methods: {
    create() {
      this.fieldsChanged = false;

      // @ts-ignore
      this.$refs.createSupplierForm.validate().then((formResult: any) => {
        this.errorMessage = null;
        if (!this.country) {
          this.errorMessage = "Please choose country to continue";
        }
        if (!this.acceptTerms) {
          this.errorMessage = "Need to accept usage terms and privacy policy";
        }
        if (formResult.valid && !this.errorMessage) {
          this.isDataProcessing = true;
          this.buttonDisabled = true;
          HTTP.post("/platform/v1/supplier/create", {
            companyName: this.name,
            country: this.country
          })
            .catch(error => {
              this.errorMessage =
                error.response.status == 500
                  ? "Something went wrong"
                  : error.response.data.message;
              this.buttonDisabled = false;
              this.isDataProcessing = false;
            })
            .then((response: any) => {
              // prettier-ignore
              HTTP.get("/auth/token/" + response.data.type + "/" + response.data.systemName).then((authData: any) => {
                localStorage.setItem("buyme-supplier:session", JSON.stringify({
                    isAuthenticated: true,
                    authenticated: authData.data
                  })
                );
                this.isDataProcessing = false;
                // prettier-ignore
                window.location.replace(window.location.protocol + "//" + window.location.hostname + "/supplier");
              });
            });
        }
      });
    },
    loadCountries() {
      HTTP.get("/platform/v1/countries").then(response => {
        this.countries = response.data;
      });
    }
  }
});
