
import { defineComponent } from "vue";
interface CodeInput {
  index: number;
  value: string;
}

export default defineComponent ({
  name: "UiCodeInput",
  emits: ["submit", "resendCode"],
  props: {
    codeResent: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      codeInputs: {
        0: { index: 0, value: "" },
        1: { index: 1, value: "" },
        2: { index: 2, value: "" },
        3: { index: 3, value: "" },
        4: { index: 4, value: "" }
      } as { [key: number]: CodeInput },
      isCodeValid: true,
      showErrorMessage: false,
    }
  },
  computed: {
    codeValue(): string {
      let code = "";

      for(let key in this.codeInputs) {
        code += this.codeInputs[key].value;
      }

      return code;
    }
  },
  methods: {
    submitHandler() {
      this.validateCode()

      if (this.isCodeValid && !!this.codeValue) {
        this.showErrorMessage = false;
        this.$emit("submit", this.codeValue);
      } else {
        this.showErrorMessage = true;
      }
    },
    resendCodeHandler() {
      this.$emit("resendCode");
    },
    codeInputHandler(index: number) {
      this.showErrorMessage = false;
      const currentValue = this.codeInputs[index].value
      //@ts-ignore
      this.$refs[`code-${index}`][0].value = currentValue
      const currentValueAsArray = String(currentValue).split('').slice(0, 5)

      if (currentValueAsArray.length > 1) {
        if (index < 4) {
          for (let i=0; i < currentValueAsArray.length; i++) {
            if ((index + i) < 5) {
              this.codeInputs[index + i].value = currentValueAsArray[i]
            }
          }
          if (index + currentValueAsArray.length < 5) {
            //@ts-ignore
            this.$refs[`code-${index + currentValueAsArray.length}`][0].focus()
            //@ts-ignore
          } else this.$refs[`code-4`][0].focus()
        } else {
          this.codeInputs[index].value = currentValueAsArray[0]
        }
      } else if (currentValueAsArray.length === 1 && index < 3) {
        //@ts-ignore
        this.$refs[`code-${index+1}`][0].focus()
      }
    },
    backToPrevCodeInput(index: number) {
      if (index && this.codeInputs[index].value === '') {
        //@ts-ignore
        this.$refs[`code-${index-1}`][0].focus()
      }
    },
    validateCode() {
      this.isCodeValid = this.codeValue.length === 5
    }
  }
})
