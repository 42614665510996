
import { defineComponent, ref, onMounted } from "vue";
import { HTTP } from "@/main";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import Header from "@/components/Header.vue";
import InfoBlock from "@/components/InfoBlock.vue";
import UiPreloaderContainer from "@/components/ui-components/preloader/UiPreloaderContainer.vue";
import UiPreloader from "@/components/ui-components/preloader/UiPreloader.vue";
import IconProfile from "@/components/icons/platform/IconProfile.vue";
import IconArrowRight from "@/components/icons/platform/IconArrowRight.vue";
import IconSystem from "@/components/icons/platform/IconSystem.vue";
import {DataGTM} from "@/consts/gtm";
import {gtmDateTimestamp} from "@/helpers/date";

export default defineComponent({
  name: "PlatformIndex",
  components: {
    Header,
    InfoBlock,
    UiPreloaderContainer,
    UiPreloader,
    IconProfile,
    IconArrowRight,
    IconSystem
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();
    const redirectUrl = ref('');
    //@ts-ignore
    const user = JSON.parse(localStorage.getItem('auth-data'))?.user;
    //@ts-ignore
    const defaultAuthData = JSON.parse(localStorage.getItem('auth-data'));
    const userConfirmNow = ref(false);
    const systems = ref(null);
    const errorMessage = ref(null);
    const supplierCreated = ref(false);
    const loaded = ref(false);

    function confirmUser() {
      if (route.query.confirmToken && !user.emailVerified) {
        HTTP.get('/auth/register/confirm/' + route.query.confirmToken).then(() => {
          //@ts-ignore
          const authData = JSON.parse(localStorage.getItem('auth-data'));
          authData.user.emailVerified = true;
          user.emailVerified = true;
          localStorage.setItem('auth-data', JSON.stringify(authData))
          userConfirmNow.value = true;
          const query = Object.assign(route.query)
          delete query['confirmToken']
          router.replace({name: 'dashboard', query: query})
        })
      }
    }

    function createSystem(routeName: string) {
      if (!user.emailVerified) {
        return;
      }
      router.push({ name: routeName });
    }

    function redirect(system: any, cardHandler = false) {
      if (cardHandler && !user.emailVerified) return;

      const authData = localStorage.getItem('auth-data');
      if (!authData) {
        router.replace({name: 'sign-in-page'})
        return;
      }

      if (defaultAuthData.token !== JSON.parse(authData).token) {
        window.location.reload();
        return;
      }
      // @ts-ignore
      generateToken(system).then(() => {
        localStorage.setItem("hr-system-entrance", "true");
        // @ts-ignore
        window.location.replace(redirectUrl.value);
      })
    }

    function generateToken(system: any) {
      //@ts-ignore
      const authData = JSON.parse(localStorage.getItem('auth-data'));
      if (system.type === "system") {
        return HTTP.get("auth/token/system/" + system.systemName).then(
            (response: any) => {
              localStorage.setItem(
                  "ember_simple_auth:session",
                  JSON.stringify(response.data)
              );
              localStorage.setItem('hrSystemName', system.systemName);
              localStorage.setItem("hr-system-entrance", "true");
              redirectUrl.value = window.location.origin + "/systems/" + system.systemName + '/v#/dashboard';
            }
        ).catch((error) => {
          if (error.response.status === 404) {
            window.location.reload();
          }
        })
      }

      if (system.type === "supplier") {
        return HTTP.get("auth/token/" + system.type + "/" + system.systemName).then(
            (response: any) => {
              localStorage.setItem(
                  "buyme-supplier:session",
                  JSON.stringify({
                    isAuthenticated: true,
                    authenticated: response.data
                  })
              );

              redirectUrl.value = window.location.origin + "/supplier"
            }
        ).catch((error) => {
          if (error.response.status === 404) {
            window.location.reload();
          }
        });
      }
    }

    function loadData() {
      HTTP.get('/platform/v1/index').then((response: any) => {
        if (response?.data?.code == 77) { // 77 - CODE_NOT_FINISHED_REGISTRATION
          router.push({name: "sign-up-confirm-data"});
        } else {
          systems.value = response.data;
          loaded.value = true
        }
      }).catch((error) => {
        //@ts-ignore
        if (error.response.status == 401) {
          localStorage.removeItem("auth-data");
          localStorage.removeItem("buyme-supplier:session");
          localStorage.removeItem("ember_simple_auth:session");
          localStorage.removeItem("available-systems");
          localStorage.removeItem('hrSystemName');
          router.replace({ name: "sign-in-page" });
        }
        //@ts-ignore
        errorMessage.value = error.response.data.message
      })
    }

    function addAccount() {
      HTTP.get('/platform/v1/check-data').then((response: any) => {
        if (response?.data?.code == 76) { // 76 - CODE_NOT_FINISHED_REGISTRATION_NAME
          router.push({ name: "confirm-data" });
        } else {
          router.push({ name: "create-hr-system" });
        }
      }).catch((error) => {
        //@ts-ignore
        if (error.response.status == 401) {
          localStorage.removeItem("auth-data");
          localStorage.removeItem("buyme-supplier:session");
          localStorage.removeItem("ember_simple_auth:session");
          localStorage.removeItem("available-systems");
          localStorage.removeItem('hrSystemName');
          router.replace({ name: "sign-in-page" });
        }
        //@ts-ignore
        errorMessage.value = error.response.data.message
      })
    }

    onMounted(async () => {
      if (localStorage.getItem('need-to-reload')) {
        localStorage.removeItem('need-to-reload');
        window.location.reload();
        return;
      }
      await confirmUser()
      await loadData();

      if (localStorage.getItem("login-completed")) {
        const authData = JSON.parse(localStorage.getItem("auth-data") ?? "{}");

        window.dataLayer = window.dataLayer || [];

        const relatedDataGTM = {
          flow: 'login',
          stage: 'completed',
          'user-id': authData?.user?.uid + '' || '',
          'user-name': authData?.username,
          'user-email': authData?.user?.mail,
          'system-view': 'login_completed',
          'user-creation-date': authData?.user?.created ? gtmDateTimestamp(authData?.user?.created) : '',
        };

        window.dataLayer?.push({ ...DataGTM, ...relatedDataGTM });

        localStorage.removeItem("login-completed");
      }
    });

    return {
      redirect,
      loadData,
      createSystem,
      addAccount,
      supplierCreated,
      loaded,
      systems,
      user,
      errorMessage,
      userConfirmNow
    };
  }
});
