<template>
  <div class="page-content form-page">
    <Header close-icon />

    <div class="content-title text-center">{{ pageLabel }}</div>

    <div class="container">
      <div class="form-container">
        <div v-if="$route.name === 'invite'" id="social-buttons">
          <div class="btn-outer">
            <button
                class="btn btn--auth btn--google"
                type="button"
                :disabled="isDataProcessing"
                @click="useAuthProvider('google', null)"
            >
              <img
                  class="content-icon"
                  width="18"
                  :src="require('@/assets/images/icon-google.svg')"
                  alt="Icon"
              />

              <span class="btn__text">{{
                  $t("button.continueWithGoogle")
                }}</span>
            </button>
          </div>

          <div class="btn-outer">
            <button
                :disabled="isDataProcessing"
                type="button"
                class="btn btn--auth btn--google"
                @click="loginByOkta"
            >
              <IconOkta class="content-icon" />

              <span class="btn__text">{{
                  $t("text.continueWithOkta")
                }}</span>
            </button>
          </div>

          <div class="btn-outer">
            <button
                :disabled="isDataProcessing"
                type="button"
                class="btn btn--auth btn--google"
                @click="loginByAzure"
            >
              <IconMicrosoft class="content-icon" />

              <span class="btn__text">
                {{$t("text.continueWithMicrosoft") }}
              </span>
            </button>
          </div>

          <div v-if="false" class="btn-outer">
            <button
                class="btn btn--auth btn--apple"
                type="button"
                :disabled="isDataProcessing"
            >
              <img
                  class="content-icon"
                  width="18"
                  :src="require('@/assets/images/icon-apple.svg')"
                  alt="Icon"
              />

              <span class="btn__text">{{
                  $t("button.continueWithApple")
                }}</span>
            </button>
          </div>

          <hr class="delimitter" />
        </div>

        <Form ref="resetPasswordForm" class="content-form" v-slot="{ errors }">
          <div class="field field--input">
            <button class="field-input-button"
                    type="button"
                    @click.stop="isPasswordVisible = !isPasswordVisible"
            >
              <img
                height="20"
                width="20"
                :src="isPasswordVisible ? require('@/assets/images/icon-password-no-view.svg') : require('@/assets/images/icon-password-view.svg')"
                alt="Show"
              />
            </button>
            <Field
              v-model="password"
              :type="isPasswordVisible ? 'text' : 'password'"
              name="password"
              class="form-control"
              readonly
              @focus="removeReadOnly"
              @input="fieldsChanged = true"
              rules="requiredPass|strongPass"
              :placeholder="$t('placeholder.enterPassword')"
            >
            </Field>
          </div>

          <div class="field field--input">
            <button class="field-input-button"
                    type="button"
                    @click.stop="isPasswordVisible = !isPasswordVisible"
            >
              <img
                height="20"
                width="20"
                :src="isPasswordVisible ? require('@/assets/images/icon-password-no-view.svg') : require('@/assets/images/icon-password-view.svg')"
                alt="Show"
              />
            </button>
            <Field
              v-model="confirmPassword"
              :type="isPasswordVisible ? 'text' : 'password'"
              class="form-control"
              readonly
              @focus="removeReadOnly"
              @input="fieldsChanged = true"
              name="confirmPassword"
              rules="requiredPassCurrent|confirmed:@password"
              :placeholder="$t('placeholder.confirmPassword')"
            >
            </Field>
          </div>

          <div class="btn-outer">
            <button
              @click.prevent="resetPassword"
              type="button"
              class="btn btn--secondary"
              :disabled="isDataProcessing"
            >
              {{ pageLabel }}
            </button>
          </div>

          <ul v-if="password"
              class="password-validation">
            <li class="password-validation__option"
                :class="{'password-validation__option_success': passHasMinLength}"
            >
              <img v-if="passHasMinLength"
                   :src="require('@/assets/images/icon-check.svg')"
                   class="password-validation__icon"
                   alt="check"
              >
              {{ $t('text.passwordValidation.characters') }}
            </li>
            <li class="password-validation__option"
                :class="{'password-validation__option_success': passContainsCapitalLetters}"
            >
              <img v-if="passContainsCapitalLetters"
                   :src="require('@/assets/images/icon-check.svg')"
                   class="password-validation__icon"
                   alt="check"
              >
              {{ $t('text.passwordValidation.capitalLetter') }}
            </li>
            <li class="password-validation__option"
                :class="{'password-validation__option_success': passContainsNumbers}"
            >
              <img v-if="passContainsNumbers"
                   :src="require('@/assets/images/icon-check.svg')"
                   class="password-validation__icon"
                   alt="check"
              >
              {{ $t('text.passwordValidation.number') }}
            </li>
            <li v-if="confirmPassword"
                class="password-validation__option"
                :class="{'password-validation__option_success': passEqualsConfirmPass}"
            >
              <img v-if="passEqualsConfirmPass"
                   :src="require('@/assets/images/icon-check.svg')"
                   class="password-validation__icon"
                   alt="check"
              >
              {{ $t('text.passwordValidation.match') }}
            </li>
          </ul>

          <template v-if="!fieldsChanged">
            <div v-if="Object.keys(errors).length" class="error text-center">
              {{ errors.password || errors.confirmPassword }}
            </div>

            <div v-else-if="errorMessage" class="error text-center">
              {{ errorMessage }}
            </div>
          </template>
        </Form>

        <UiPreloaderContainer
            v-if="isDataProcessing"
            class="bg-white bg-white--transparent"
            absolute
        >
          <UiPreloader />
        </UiPreloaderContainer>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, ref, computed, onMounted} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import axios from "axios";
import {HTTP} from "@/main";
import helpers from "@/helpers";
import { Providers } from "universal-social-auth";
import {getAzureLink, getOktaLink} from "@/services/sign-in.service";
import { useFingerprint } from "@/composables/useFingerprint";
import { Form, Field } from "vee-validate";

import Header from "@/components/Header.vue";
import UiPreloaderContainer from "@/components/ui-components/preloader/UiPreloaderContainer.vue";
import UiPreloader from "@/components/ui-components/preloader/UiPreloader.vue";
import IconOkta from "@/components/icons/IconOkta.vue";
import IconMicrosoft from "@/components/icons/IconMicrosoft.vue";

export default defineComponent({
  name: "ResetPassword",
  components: { Form, Field, Header, UiPreloaderContainer, UiPreloader, IconOkta, IconMicrosoft },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();

    const { getRequestId } = useFingerprint();

    const password = ref(null);
    const confirmPassword = ref(null);
    const errorMessage = ref(null);
    const fieldsChanged = ref(false);
    const isPasswordVisible = ref(false);
    const isDataProcessing = ref(false);
    // @ts-ignore
    const pageLabel = computed(() => t("pageLabel." + route.name));
    // @ts-ignore
    const passHasMinLength = computed(() => password.value?.length >= 8);
    const passContainsCapitalLetters = computed(() => /[A-Z]/.test(password.value || ""));
    const passContainsNumbers = computed(() => /\d/.test(password.value || ""));
    const passEqualsConfirmPass = computed(() => password.value === confirmPassword.value);
    const isPasswordValid = computed(() => {
      return passHasMinLength.value && passContainsCapitalLetters.value && passContainsNumbers.value && passEqualsConfirmPass.value;
    });

    const resetPasswordForm = ref(null);

    const removeReadOnly = event => {
      event.preventDefault();
      if (event.target.getAttribute("readonly") === "") {
        event.target.removeAttribute("readonly");
      }
    };

    const hrSystemEntrance = async (systems, token) => {
      const redirectUrl = await helpers.generateToken(systems.data[0], token);
      localStorage.setItem("hr-system-entrance", "true");
      if (redirectUrl) window.location.replace(redirectUrl);
    };

    const redirectAfterLogin = async user => {
      const token = user.token;
      const systems = await helpers.loadSystems(token);
      isDataProcessing.value = false;

      if (route.name === "invite") {
        return await hrSystemEntrance(systems, token);
      }

      if (!systems.data.length) {
        localStorage.setItem("utm_source", "");
        localStorage.setItem("utm_medium", "");
        localStorage.setItem("utm_campaign", "");

        localStorage.setItem("emptySystemsList", "true");

        if (systems.data.code === 76) { // CODE_NOT_FINISHED_REGISTRATION_NAME
          document.location.replace(window.location.origin + "/gifting-platform#/sign-up/confirm-data");
        }
        if (systems.data.code === 77) { // CODE_NOT_FINISHED_REGISTRATION
          document.location.replace(window.location.origin + "/gifting-platform#/create-hr-system");
        }
        return;
      }

      if (!user.user.emailVerified) {
        window.location.replace(
            window.location.origin + "/gifting-platform#/dashboard"
        );
      }

      if (systems.data.length > 1) {
        window.location.replace(
            window.location.origin + "/gifting-platform#/dashboard"
        );
      } else {
        await hrSystemEntrance(systems, token);
      }
    };

    const resetPassword = async () => {
      fieldsChanged.value = false;

      try {
        // @ts-ignore
        const { valid } = await resetPasswordForm.value?.validate();
        if (valid && isPasswordValid.value) {
          isDataProcessing.value = true;
          await axios.post("/auth/resetPassword", {
            password: password.value,
            confirmPassword: confirmPassword.value,
            token: route.query.token
          });
          const { data } = await axios.post("auth/login/token", {
            resetToken: route.query.token
          });
          localStorage.setItem("auth-data", JSON.stringify(data));
          localStorage.setItem("need-to-reload", "true");
          localStorage.setItem("first-login", "true");
          await redirectAfterLogin(data);
        }
      } catch (error) {
        isDataProcessing.value = false;
        errorMessage.value = error.response.data.message;
        if (error.response.status === 422)
          await router.push({ name: "sign-in-page" });
      }
    };

    const socialLoginCallback = response => {
      localStorage.setItem("auth-data", JSON.stringify(response.data));
      localStorage.removeItem("signInEmail");
      localStorage.setItem("need-to-reload", "true");
      localStorage.setItem("first-login", "true");
      localStorage.setItem("registered-via-social", "true");
      localStorage.setItem("login-completed", "true");

      redirectAfterLogin(response.data);
    };

    const loginByOkta = async () => {
      try {
        isDataProcessing.value = true;

        const response = await getOktaLink();

        if (response.data?.url) {
          localStorage.setItem("social-auth-type", "okta");
          window.location.assign(response.data.url);
        }

        isDataProcessing.value = false;
      } catch (error) {
        isDataProcessing.value = false;
        console.error(error);
      }
    };

    const loginByAzure = async () => {
      try {
        isDataProcessing.value = true;

        const response = await getAzureLink();

        if (response.data?.url) {
          localStorage.setItem("social-auth-type", "azure");
          window.location.assign(response.data.url);
        }

        isDataProcessing.value = false;
      } catch (error) {
        isDataProcessing.value = false;
        console.error(error);
      }
    };

    async function useAuthProvider(provider, proData) {
      const pro = proData;
      const ProData = pro || Providers[provider];
      const requestId = await getRequestId();

      if (this.$oauth) {
        this.$oauth
          .authenticate(provider, ProData)
          .then((response) => {
            if (response.code) {
              isDataProcessing.value = true;
              HTTP.post("/auth/social/" + provider, {
                // @ts-ignore
                code: response.code,
                requestId
              })
                  .then(socialLoginCallback)
                  .catch((error) => {
                    errorMessage.value = error.response.data.message;
                    isDataProcessing.value = false;
                  });
            }
          })
          .catch((err) => {
            errorMessage.value = err.response.data.message;
          });
      }
    }

    onMounted(() => {
      if (!route.query.token || route.query.token === "") {
        router.push({ name: "sign-in-page" });
      }
    })

    return {
      isDataProcessing,
      password,
      confirmPassword,
      errorMessage,
      fieldsChanged,
      isPasswordVisible,
      pageLabel,
      passHasMinLength,
      passContainsCapitalLetters,
      passContainsNumbers,
      passEqualsConfirmPass,
      resetPasswordForm,
      removeReadOnly,
      resetPassword,
      loginByOkta,
      loginByAzure,
      useAuthProvider
    }
  }
});
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";

.field {
  position: relative;

  .field-input-button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0 17px 0 5px;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
}

.password-validation {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin: 15px 0;
  &__option {
    position: relative;
    padding-left: 25px;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    color: $color-black;
    &_success {
      color: $color-success-green;
    }
  }
  &__icon {
    position: absolute;
    top: calc(50% - 10px);
    left: 0;
    height: 20px;
    width: 20px;
    display: block;
    object-fit: contain;
  }
}

</style>
